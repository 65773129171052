// CONTACT

.contact {
  background-color: $background-color;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation-duration: 1s;
    animation-name: slidein2;
    animation-iteration-count: 1;

    h1 {
      color: $secondary-text-color;
      font-size: 800%;
      text-align: center;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    border-radius: 2px 2px 5px 5px;
    padding: 10px 20px 20px 20px;
    width: 50%;
    max-width: 800px;
    font-size: 130%;
    background: #f2f4f3;
    position: relative;
    padding-bottom: 80px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
    margin-bottom: 5em;

    label {
      color: $secondary-text-color;
      font-size: 130%;
      padding: 1em;
      text-align: center;
    }
  }

  &__input {
    display: block;
    font-size: 100%;
    padding: 15px 10px;
    margin-bottom: 10px;
    width: 100%;
    border: 1px solid #ddd;
    transition: border-width 0.2s ease;
    border-radius: 2px;
  }
  input,
  textarea {
    font-family: "Roboto", sans-serif;
  }

  &__btn {
    width: 100%;
    height: 100%;
    padding: 10px 10px;
    background: $secondary-text-color;
    color: $background-color;
    font-size: 120%;
    display: block;
    border: none;
    margin-top: 1em;
    position: absolute;
    left: 0;
    bottom: 0;
    max-height: 60px;
    border: 0px solid rgba(0, 0, 0, 0.1);
    border-radius: 0 0 2px 2px;
    border-bottom-width: 7px;
  }

  &__btn:hover {
    cursor: pointer;
    background-color: $background-color;
    color: $secondary-text-color;
  }
}

@media only screen and (max-width: 1000px) {
  .contact__form {
    label {
      font-size: 80%;
    }
  }
}

@media only screen and (max-width: 700px) {
  .contact__container {
    h1 {
      font-size: 600%;
    }
  }
  .contact__form {
    font-size: 80%;
    width: 70%;
    label {
      font-size: 95%;
    }
  }
}

@media only screen and (max-width: 400px) {
  .contact__container {
    h1 {
      font-size: 400%;
    }
  }
  .contact__form {
    label {
      font-size: 75%;
    }
  }
}
