// NAVBAR

.navbar {
  width: 5rem;
  height: 100vh;
  position: fixed;
  background-color: #171717;
  transition: width 200ms ease;

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  &__item,
  &__welcome {
    width: 100%;

    &:last-child {
      margin-top: auto;
    }

    a {
      text-decoration: none;
    }
  }

  &__welcome {
    background: $secondary-text-color;
  }

  &__link__welcome {
    display: flex;
    align-items: center;
    height: 5rem;
    color: $background-color;
    text-decoration: none;
    filter: grayscale(100%) opacity(1);
    transition: 200ms ease;

    img {
      min-width: 2rem;
      margin: 0 1.5rem;
      filter: invert(1);
    }
  }

  &__link {
    display: flex;
    align-items: center;
    height: 5rem;
    color: $background-color;
    text-decoration: none;
    filter: grayscale(100%) opacity(0.7);
    transition: 200ms ease;

    &:hover {
      filter: grayscale(0%) opacity(1);
      background: $secondary-text-color;
      color: $background-color;
      cursor: pointer;
    }

    img {
      min-width: 2rem;
      margin: 0 1.5rem;
      filter: invert(0.7);

      &:hover {
        filter: invert(1);
      }
    }
  }

  &__text {
    display: none;
    margin: 0 0.8rem;
  }
}

.navbar:hover {
  width: 16rem;
}

.navbar:hover .navbar__text {
  display: block;
  text-decoration: none;
}

// RESPONSIVE NAV

/* Small screens */
@media only screen and (max-width: 600px) {
  .navbar {
    bottom: 0;
    width: 100vw;
    height: 5rem;
  }

  .navbar__list {
    flex-direction: row;
  }

  .navbar__item {
    justify-content: center;
  }

  .navbar__link img {
    margin: 0 1.2rem;
    height: 2.7rem;
  }

  .navbar__text {
    font-size: 25%;
    margin: 0;
  }

  .navbar__welcome {
    display: none;
  }
}

@media only screen and (max-width: 460px) {
  .navbar__text {
    font-size: 0%;
    padding: 0;
    margin: 0;
  }
}

@media only screen and (max-width: 400px) {
  .navbar__link img {
    margin: 0 1.2rem;
    height: 2rem;
    min-width: 1rem;
  }
}

@media only screen and (max-width: 320px) {
  .navbar__link img {
    margin: 0;
    height: 2rem;
    min-width: 0.5rem;
  }
}

/* large screens */

@media only screen and (min-width: 600px) {
  .navbar {
    top: 0;
    width: 5rem;
    height: 100vh;
  }

  .navbar:hover {
    width: 16rem;
  }

  .navbar:hover .navbar__text {
    display: inline;
  }
}
