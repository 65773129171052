// @import "./style.scss";

// BOOKS

button > a {
  color: $background-color;
  text-decoration: none;

  &:hover {
    color: $secondary-text-color;
  }
}

.books {
  &__children {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 20em;
    background-color: $secondary-text-color;
    animation-duration: 1s;
    animation-name: slidein2;
    animation-iteration-count: 1;

    h1 {
      margin: 1em auto;
      font-size: 13em;
      color: $background-color;
    }
  }

  &__container {
    background-color: $background-color;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 120%;
  }

  @media only screen and (min-width: 1300px) {
    &__header {
      margin-top: 3em;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70%;
    }
    h3 {
      font-weight: 300;
    }
  }

  &__img {
    width: 100%;
    height: 400px;
    object-fit: contain;
    transition: transform 0.5s, opacity 0.5s;

    &:hover {
      cursor: pointer;
      transform: scale(1.04);
    }
  }

  &__synopsis {
    margin-top: 7em;
    margin-bottom: 3em;
    width: 65ch;
    line-height: 1.5;
    font-weight: 300;
    font-size: 120%;

    h2,
    a {
      color: $secondary-text-color;
    }
    h2 {
      font-size: 200%;
      text-align: center;
    }

    img {
    }

    blockquote {
      margin-top: 2em;
      margin-bottom: 5em;
    }
  }

  &__images {
    display: flex;
  }
  &__gallery {
    // margin-left: 2em;

    img {
      width: 250px;
      height: 220px;
      width: 100%;
      object-fit: contain;

      &:hover {
        transform: scale(1.2);
      }
    }
  }

  &__copyright {
    text-align: center;
    margin-top: 3em;
  }
}

/* Small screens */

@media only screen and (max-width: 1300px) {
  .books__header {
    display: flex;
    flex-direction: column-reverse;

    margin-top: 4em;

    text-align: center;

    h1 {
      margin-top: 2em;
    }
  }
}

@media only screen and (max-width: 880px) {
  .books__synopsis {
    width: 50ch;

    h2 {
      font-size: 150%;
    }
  }
}

@media only screen and (max-width: 700px) {
  .books__children {
    h1 {
      font-size: 10em;
    }
  }
  .books__synopsis {
    width: 45ch;

    h2 {
      font-size: 150%;
    }
  }
}

@media only screen and (max-width: 600px) {
  .books__children {
    h1 {
      font-size: 5em;
    }
  }
  .books__synopsis {
    // width: 55ch;
    font-size: 80%;

    h2 {
      font-size: 150%;
    }
  }
  .books__image {
    width: 250px;
    // height: auto;
    // object-fit: contain;
  }
}

@media only screen and (max-width: 400px) {
  .books__synopsis {
    width: 40ch;

    h2 {
      font-size: 150%;
    }
  }
}
