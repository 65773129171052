// WEB

.web {
  &__container {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url("/code.jpg");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    // height: 100vh;
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 6em;
    animation-duration: 1s;
    animation-name: slidein2;
    animation-iteration-count: 1;
    h1 {
      margin-top: 1em;
      font-size: 800%;
      color: $background-color;
    }

    h2,
    h3 {
      color: $background-color;
    }
  }

  &__line {
    width: 100%;
    height: 0.2rem;
    background-color: $secondary-text-color;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: thin;
  }

  &__box {
    margin-top: 4em;
    margin-bottom: 3em;
    width: 65ch;
    line-height: 2;
    font-weight: 300;
    font-size: 130%;

    h2,
    a {
      color: $secondary-text-color;
    }

    a {
      font-weight: bold;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    h2 {
      font-size: 200%;
      text-align: center;
    }
  }

  &__tech {
    margin: 3em 3.5em 2em 3.5em;
    display: flex;
    flex-wrap: wrap;
  }

  &__icons {
    margin: 1em 1em 3em 1.4em;
    position: relative;
    overflow: hidden;

    &:hover {
      visibility: visible;
    }

    img {
      width: 100px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__name {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    top: 1;
    right: 0;
    width: 100%;
    height: 100%;
    font-weight: bold;
    background: $background-color;
    color: $secondary-text-color;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.25s;
  }

  &__btn {
    font-family: "Roboto", sans-serif;
    color: $background-color;
    background-color: $secondary-text-color;
    padding: 0.5em 1em;
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;

    &:hover {
      cursor: pointer;
      background-color: $background-color;
      color: $secondary-text-color;
    }
  }

  &__contact {
    margin-bottom: 4em;
    text-align: center;
  }
}

.web__icons:hover .web__name {
  visibility: visible;
  opacity: 1;
}

/* Small screens */
@media only screen and (max-width: 900px) {
  .web__header {
    h1 {
      margin-top: 1em;
      font-size: 600%;
    }

    h2,
    h3 {
      font-size: 100%;
    }
  }
}

.web__box {
  // font-size: 105%;
}

@media only screen and (max-width: 700px) {
  .web__header {
    h1 {
      margin-top: 2em;
      font-size: 400%;
    }

    h2,
    h3 {
      font-size: 70%;
    }
  }

  .web__box {
    width: 55ch;

    h2 {
      font-size: 150%;
    }
  }

  .web__tech {
    margin: 3em 1em 2em 1em;
  }
}

@media only screen and (max-width: 600px) {
  .web__container {
    height: 70vh;
  }
  .web__header {
    h1 {
      margin-top: 3em;
      font-size: 350%;
    }

    h2,
    h3 {
      font-size: 80%;
    }
  }
  .web__box {
    font-size: 80%;
  }

  .web__tech {
    justify-content: center;
  }
}

@media only screen and (max-width: 500px) {
  .web__container {
    height: 50vh;
  }
  .web__header {
    h1 {
      margin-top: 3em;
      font-size: 300%;
    }

    h2,
    h3 {
      font-size: 70%;
    }
  }
  .web__box {
    width: 50ch;
  }
}

@media only screen and (max-width: 400px) {
  .web__header {
    h1 {
      margin-top: 2.7em;
      font-size: 250%;
    }

    h2,
    h3 {
      font-size: 50%;
    }
  }
  .web__box {
    width: 45ch;
    font-size: 70%;
  }

  .web__icons {
    img {
      width: 80px;
    }
  }
}
