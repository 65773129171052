// ABOUT

.about {
  font-family: "Roboto", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    color: $background-color;
    background-color: $secondary-text-color;
  }

  &__hello {
    font-size: 250%;
    padding: 1em;
    animation-duration: 2s;
    animation-name: slidein2;
    animation-iteration-count: 1;
  }

  &__facts {
    display: flex;
    font-size: 150%;
    font-weight: 300;
    padding: 2em;
    background-color: $background-color;
    color: $primary-text-color;
  }

  // &__btn {
  //   font-family: "Roboto", sans-serif;
  //   color: $background-color;
  //   background-color: $secondary-text-color;
  //   padding: 0.5em 1em;
  //   text-align: center;
  //   border: none;
  //   text-align: center;
  //   font-weight: bold;
  //   font-size: 60%;
  //   text-decoration: none;
  //   display: inline-block;

  //   &:hover {
  //     cursor: pointer;
  //     background-color: $background-color;
  //     color: $secondary-text-color;
  //   }
  // }

  &__img {
    width: 100%;
    height: 400px;
    object-fit: contain;
    transition: transform 0.5s, opacity 0.5s;

    &:hover {
      cursor: pointer;
      transform: scale(1.04);
    }
  }

  &__contact {
    margin-bottom: 2em;
    a {
      color: $secondary-text-color;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__line {
    width: 100%;
    height: 0.2rem;
    background-color: $secondary-text-color;
  }
}

@media only screen and (max-width: 1300px) {
  .about__hello {
    padding: 1.8em;
    font-size: 200%;
  }

  .about__facts {
    font-size: 130%;
  }
}

@media only screen and (max-width: 1190px) {
  .about__facts {
    font-size: 110%;
  }

  .about__img {
    width: 100%;
    height: 350px;
    object-fit: contain;
  }
}

@media only screen and (max-width: 1000px) {
  .about__hello {
    padding: 1.9em;
    font-size: 170%;
  }
  .about__facts {
    font-size: 90%;
    padding: 4em;
  }

  .about__img {
    width: 100%;
    height: 300px;
    object-fit: contain;
  }
}

@media only screen and (max-width: 900px) {
  .about__hello {
    padding: 1.9em;
    font-size: 150%;
  }
  .about__facts {
    font-size: 80%;
    padding: 4em;
  }

  .about__img {
    width: 100%;
    height: 250px;
    object-fit: contain;
  }
}

@media only screen and (max-width: 800px) {
  .about__hello {
    padding: 1.9em;
    font-size: 130%;
  }
  .about__facts {
    font-size: 70%;
    padding: 4em;
  }

  .about__img {
    width: 100%;
    height: 200px;
    object-fit: contain;
  }
}

@media only screen and (max-width: 700px) {
  .about__hello {
    padding: 3.5em;
    font-size: 100%;
  }
  .about__facts {
    font-size: 80%;
    padding: 4em;
    flex-direction: column;
  }

  .about__img {
    width: 100%;
    height: 400px;
    object-fit: contain;
  }
}

@media only screen and (max-width: 500px) {
  .about__hello {
    padding: 3.5em;
    font-size: 70%;
  }
  .about__facts {
    font-size: 80%;
    padding: 2em;
    margin-top: 4em;
  }

  .about__img {
    width: 100%;
    height: 400px;
    object-fit: contain;
  }
}

@media only screen and (max-width: 400px) {
  .about__hello {
    padding: 1em auto;
    text-align: center;
    margin-top: 4em;
    font-size: 60%;

    h5 {
      font-size: 110%;
    }
  }
}
