// SCREENWRITER

.screenwriter {
  font-family: "Roboto", sans-serif;
  color: $background-color;

  &__line {
    width: 100%;
    height: 0.2rem;
    background-color: $secondary-text-color;
  }

  &__container {
    // background-image: url("/manuscript.jpg");
    // background-attachment: fixed;
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__header {
    margin-top: 10em;
    h1 {
      font-size: 850%;
      // height: 50vh;
      color: $secondary-text-color;
      overflow: hidden; /* Ensures the content is not revealed until the animation */
      border-right: 0.15em solid $secondary-text-color; /* The typwriter cursor */
      white-space: nowrap; /* Keeps the content on a single line */
      margin: 0 auto; /* Gives that scrolling effect as the typing happens */
      letter-spacing: 0.15em; /* Adjust as needed */
      animation: typing 2s steps(9, end), blink-caret 0.75s step-end infinite;

      /* The typing effect */
      @keyframes typing {
        from {
          width: 0;
        }
        to {
          width: 100%;
        }
      }

      /* The typewriter cursor effect */
      @keyframes blink-caret {
        from,
        to {
          border-color: transparent;
        }
        50% {
          border-color: $secondary-text-color;
        }
      }
    }
  }

  // &__btn {
  //   margin-top: 1em;
  //   f
  // }

  &__btn,
  &__btn-touch {
    font-family: "Roboto", sans-serif;
    font-size: 100%;
    color: $background-color;
    background-color: $secondary-text-color;
    padding: 0.5em 1em;
    border: 1px solid transparent;
    text-align: center;
    text-decoration: none;
    display: inline-block;

    &:hover {
      cursor: pointer;
      background-color: $background-color;
      color: $secondary-text-color;
      border: 1px solid $secondary-text-color;
    }
  }

  &__quote {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 110%;
    // background: rgba(0, 0, 0, 0.322);
    background-color: $secondary-text-color;
    color: $background-color;
    text-align: center;
    padding: 3em 2em;
  }

  &__main {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primary-text-color;
    font-weight: thin;
    margin-bottom: 4em;
    // height: 100vh;

    span {
      color: $secondary-text-color;
    }
  }
  &__box {
    margin: 4em 0;
    width: 65ch;
    line-height: 2;
    font-weight: 300;
    font-size: 130%;
  }

  h2 {
    text-align: center;
    font-size: 200%;
    color: $secondary-text-color;
  }

  &__projects {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    padding: 1em;
    margin: 2em 4em;
  }

  &__poster {
    margin-bottom: 1.5em;
    img {
      height: 500px;
      transition: transform 0.5s, opacity 0.5s;

      &:hover {
        cursor: pointer;
        transform: scale(1.03);
      }
    }
  }

  &__testimonials {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url("/paper.jpg");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top: 6em;

    h2 {
      color: $background-color;
      padding: 1.2em;
    }
  }

  &__row {
    max-width: 1140px;
    margin: 1em auto;
  }

  &__blockquote {
    padding: 2%;
    font-style: italic;
    line-height: 145%;
    position: relative;
    margin-top: 40px;

    &:before {
      content: "\201C";
      font-size: 500%;
      display: block;
      position: absolute;
      top: -5px;
      left: -5px;
    }
  }

  cite {
    color: $background-color;
    margin-top: 25px;
    display: block;
  }

  &__contact {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primary-text-color;
    line-height: 2;
    font-weight: 300;
    font-size: 130%;
    margin-bottom: 4em;
  }

  &__info {
    margin: 2em 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a {
      color: $secondary-text-color;
    }
  }
}

.rec .rec-dot_active:hover {
  border: none;
  outline: none;
  box-shadow: 0 0 1px 3px #171717;
}

.rec .rec-arrow-right,
.rec .rec-arrow-left,
.rec .rec-dot_active {
  color: $background-color;
  background-color: $secondary-text-color;
  // box-shadow: 0 0 2px 0 #333;
}

.rec .rec-arrow-right:hover:enabled,
.rec .rec-arrow-left:hover:enabled,
.rec .rec-arrow-right:focus:enabled,
.rec .rec-arrow-left:focus:enabled {
  color: $secondary-text-color;
  background-color: $background-color;
  // box-shadow: 0 0 2px 0 #333;
}

// MODALS

body.active-modal {
  overflow-y: hidden;
}

.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}
.modal-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: rgba(0, 0, 0, 0.548);
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 300px;
}

.modal-content-flex {
  display: flex;

  img {
    height: 200px;
  }

  ul {
    list-style: none;
  }

  li {
    line-height: 2;
  }
  li a {
    color: $secondary-text-color;

    &:hover {
      color: $background-color;
    }
  }
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
  background: $secondary-text-color;
  color: $background-color;
  outline: none;
  border: none;

  &:hover {
    cursor: pointer;
    background-color: $background-color;
    color: $secondary-text-color;
  }
}

@media only screen and (max-width: 1145px) {
  .screenwriter__header {
    h1 {
      font-size: 600%;
    }
  }
  .screenwriter__contact {
    line-height: 1.5;
    // font-size: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .screenwriter__header {
    h1 {
      font-size: 400%;
    }
  }

  .screenwriter__quote {
    font-size: 80%;
    padding: 1em 1em;
  }

  .screenwriter__box,
  .screenwriter__contact {
    font-size: 100%;
  }

  .modal-content-flex {
    img {
      height: 200px;
    }

    li {
      font-size: 80%;
    }
  }
}

@media only screen and (max-width: 700px) {
  .screenwriter__header {
    h1 {
      font-size: 400%;
    }
  }

  .screenwriter__box,
  .screenwriter__contact {
    font-size: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .screenwriter__header {
    h1 {
      font-size: 300%;
    }
  }

  .modal-content-flex {
    flex-direction: column;
  }

  .screenwriter__quote {
    font-size: 70%;
    padding: 1em 0.5em;
  }

  .screenwriter__box,
  .screenwriter__contact,
  .screenwriter__blockquote {
    font-size: 70%;
  }

  .screenwriter__info {
    width: 65ch;
    text-align: center;
  }

  .screenwriter__poster {
    img {
      height: 300px;
      width: auto;
    }
  }
}

@media only screen and (max-width: 500px) {
  .screenwriter__header {
    h1 {
      font-size: 250%;
    }
  }
  .screenwriter__box,
  .screenwriter__contact,
  .screenwriter__blockquote {
    font-size: 60%;
  }

  .screenwriter__info {
    width: 65ch;
    text-align: center;
  }

  .screenwriter__poster {
    img {
      // height: 250px;
      // width: auto;
    }
  }

  .rec .rec-carousel {
    width: 350px;
  }
}

@media only screen and (max-width: 400px) {
  .screenwriter__header {
    h1 {
      font-size: 250%;
    }
  }
  .screenwriter__testimonials {
    h2 {
      font-size: 160%;
      padding: 1em;
    }
  }
  .screenwriter__box,
  .screenwriter__contact,
  .screenwriter__blockquote {
    font-size: 50%;
  }

  .screenwriter__info {
    width: 65ch;
    text-align: center;
  }

  .screenwriter__poster {
    img {
      height: 300px;
      width: 300px;
      object-fit: contain;
    }
  }
}
