// FOOTER

.footer {
  background-color: $primary-text-color;
  padding: 1em;
  font-size: 40%;

  @media (min-width: 700px) {
    font-size: 70%;
  }

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  &__nav {
    list-style: none;
    display: flex;
    flex-direction: column;

    @media (max-width: 760px) {
      font-size: 70%;
    }
  }

  &__nav li,
  &__nav__social li {
    display: inline-block;
    padding: 0.5em;

    img:hover {
      opacity: 0.5;
    }

    @media (max-width: 760px) {
      img {
        width: 20px;
      }
    }
  }

  &__nav li {
    color: $background-color;

    &:hover {
      color: $secondary-text-color;
    }
  }

  &__nav li a:link,
  &__nav li a:visited {
    text-decoration: none;
    border: 0;
    color: $secondary-text-color;
    transition: color 0.2s;
  }

  &__nav li a:hover,
  &__nav li a:active {
    color: $secondary-text-color;
  }

  &__copyright {
    color: $background-color;
    text-align: center;
    margin-top: 1em;

    a {
      color: $background-color;

      &:hover {
        color: $secondary-text-color;
      }
    }

    @media (max-width: 760px) {
      font-size: 70%;
    }

    @media (max-width: 500px) {
      font-size: 60%;
      margin-left: 10em;
    }
  }
}
