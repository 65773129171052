$primary-text-color: #454545;
$secondary-text-color: #e15d44;
$background-color: #fff;
$purple: #2c1630;
$third-text-color: #e2435d;

@import "Styles/books";
@import "Styles/web";
@import "Styles/footer";
@import "Styles/contact";
@import "Styles/screenwriter";
@import "Styles/about";
@import "Styles/home";
@import "Styles/nav";

// REUSABLE COMPONENTS

* {
  box-sizing: border-box;
}

body,
html {
  padding: 0;
  margin: 0;
  // font-family: "Bitter", serif;
  // font-family: "Carter One", cursive;
  font-family: "Roboto", sans-serif;
}

.back {
  position: fixed;
  img {
    margin: 2em;
    filter: invert(0.8);

    &:hover {
      filter: invert(0.2);
      -webkit-animation: spin 1s linear 1;
      -moz-animation: spin 1s linear 1;
      animation: spin 1s linear 1;
    }
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 700px) {
  .back {
    img {
      margin: 1em;
      width: 30px;
    }
  }
}
