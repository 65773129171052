// HERO

.hero {
  &__center,
  &__bottom {
    align-items: center;
    background-color: $background-color;
    color: $secondary-text-color;
    display: flex;
  }

  &__center {
    margin-top: 5em;
    justify-content: center;
    color: $background-color;
    background-color: $secondary-text-color;
    animation-duration: 1.5s;
    animation-name: slidein1;
    animation-iteration-count: 1;
  }

  &__bottom {
    justify-content: flex-end;
    margin-right: 2em;
  }

  &__item-center {
    font-size: 1.5em;
    line-height: 0.8;
    padding: 1.5em 0;
  }

  &__item-bottom {
    font-size: 1.5em;
    line-height: 0.8;
    h1 {
      font-size: 800%;
    }
  }

  &__btn {
    margin: 1em 2em 1em 0;
    font-size: 80%;
  }

  &__btn {
    font-family: "Roboto", sans-serif;
    color: $secondary-text-color;
    background-color: $background-color;
    padding: 0.5em 0.5em;
    border: 0.5px solid transparent;
    text-align: center;

    &:hover {
      cursor: pointer;
      background-color: $secondary-text-color;
      color: $background-color;
      border: 0.5px solid $background-color;
    }
  }

  &__hello {
    animation-duration: 2s;
    animation-name: slidein2;
    animation-iteration-count: 1;
  }
}

@keyframes slidein1 {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

@keyframes slidein2 {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

// RESPONSIVE HOME

@media only screen and (max-width: 1300px) {
  .hero__center {
    justify-content: flex-end;
  }

  .hero__item-center {
    font-size: 1.2em;
    // line-height: 0.8;
    padding: 1.5em;
  }
}

@media only screen and (max-width: 930px) {
  .hero__item-center {
    width: 50ch;
    line-height: 2;
    padding: 0;
  }
}

@media only screen and (max-width: 800px) {
  .hero__item-center {
    width: 40ch;
    line-height: 2;
  }

  .hero__item-bottom {
    h1 {
      font-size: 600%;
      margin-right: 0.5em;
    }
  }
}

@media only screen and (max-width: 700px) {
  .hero__item-center {
    width: 30ch;
    line-height: 1.3;
  }

  .hero__item-bottom {
    h1 {
      font-size: 500%;
      margin-right: 0.2em;
    }
  }
}

@media only screen and (max-width: 600px) {
  .hero {
    flex-direction: row-reverse;
  }
}

@media only screen and (max-width: 400px) {
  .hero__item-center {
    width: 40ch;
    font-size: 80%;
  }

  .hero__item-bottom {
    h1 {
      font-size: 300%;
      margin-right: 1.3em;
    }
  }
}
